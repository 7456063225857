body {
  font: 14px "Century Gothic", Futura, sans-serif;
  min-height: 100vh;
  min-width: 95vw;
  margin: 0;
  background-image: url("ebbackground.png");
  background-position: 0% 0%;
}

@keyframes bgscroll {
  0% {background-position: 0 0;}
  25% {background-position: 48px 12px;}
  50% {background-position: 96px 24px;}
  75% {background-position: 144px 36px;}
  100% {background-position: 192px 48px;}
}

@keyframes seasickscroll {
  0% {background-position: 30% 0%;}
  30% {background-position: 0% 5%;}
  60% {background-position: 15% 7%;}
  90% {background-position: 0% 8%;}
  100% {background-position: 30% 0%;}
}

.scrollingBackground {
  animation-name: bgscroll;
  animation-duration: 30s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
  
.moveBox {
  background: #101010;
  color: #f1f1f1;
  font-size: 25px;
  max-width: 700px;
  padding: 20px;
}

.moveBoxHandle {
  width: 100%;
  height: 100%;
  top: -12px;
  right: -12px;

  border-style: solid;
  border-width: 12px;
  border-radius: 16px;
  border-color: #d6d9d8;
  border-image: url('./border.png') 5
}

.oldbox {
  opacity: 0.7;
}

a {
  color: #e86de8;
}

a:hover {
  color: #ffbeff;
}

a:visited {
  color: #5cc2e0;
}
