.navigation.moveBox {
    margin-top: 75px;
    margin-left: 25px;
    position: relative;
    width: 250px;
    z-index: 100;
    height: 500px;
}

.navTitle {
    font-size: 28px;
    padding-top: 2px;
    text-align: center;
    white-space: nowrap;
    color: #eaeaea;
}

.navigationList {
    list-style: none;
    padding: 0;
}

.navigationList > li{
	padding: 20px 0 25px 0;
}

.navigationList > li > a {
	text-decoration: none;
    font-weight: bold;
    padding: 20px 0 25px 35px;
}

.navigationList > li:hover{
	list-style: none;
	background-image: url('./cursor.png');
	background-repeat: no-repeat;
	background-position: left center;
	background-size: 30px;
}

.navigationList > li > a:focus-visible{
	list-style: none;
	background-image: url('./cursor.png');
	background-repeat: no-repeat;
	background-position: left center;
	background-size: 30px;
}

li::marker {
    display: inline-block;
    height: 25px;
    width: 25px;
}