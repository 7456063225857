body.scrollingChefBackground {
    background-color: rgb(255 97 30 / 73%);
    background-blend-mode: overlay;
    animation-name: bgscroll;
    animation-duration: 30s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.foodplatform {
    text-align: center;
    margin-top: 5%;
}

.moveBox {
    margin: auto;
    text-align: left;
}

input {
    margin-top:50px;
    font-size: 25px;
}

@media (max-width:785px) {
    .chef {
        width: 309px;
        height: 309px;
    }
    #cheftext {
        font-size: 18px;
    }
}

@media (max-height:900px) {
    .chef {
        width: 309px;
        height: 309px;
    }
    #cheftext {
        font-size: 18px;
    }
}